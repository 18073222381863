import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useForm } from 'react-hook-form';

import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import { Box, Divider, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { yupResolver } from '@hookform/resolvers/yup';
import { urlJoin } from 'url-join-ts';

import { FeatureToggleEnum, LoadingState } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import {
    useNewsletterApi,
    useNewsletterUi,
} from '@app/components/organisms/newsletter/newsletter.hooks';
import { SalesManagoTag } from '@app/enums';
import { environment } from '@app/environments/environment';
import { commonUiStateSlice, useAppSelector } from '@app/state';

import { getSchema } from './newsletter.def';

const ChakraNextImage = dynamic(() =>
    import('@app/components/atoms/chakra-next-image').then(c => c.ChakraNextImage),
);

export const Newsletter = () => {
    const styles = useMultiStyleConfig('Newsletter', {});
    const buttonColorScheme = styles?.button?.colorScheme as string;
    const imgSrc = urlJoin(environment.ASSETS_PATH, 'svg', 'sparkus.svg');

    const { t } = useTranslation('home', { keyPrefix: 'newsletter' });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(getSchema(t)),
    });
    const { loadingState, onSubmit } = useNewsletterApi(SalesManagoTag.HOME_PAGE);
    const { componentStyle, translation } = useNewsletterUi(loadingState);

    const FT_USE_V7_THEME = useAppSelector(
        commonUiStateSlice.selectors.selectUIFtActive<boolean>(FeatureToggleEnum.USE_V7_THEME),
    );

    return (
        <Flex
            data-testid={'newsletter-wrapper'}
            bg={componentStyle.bg}
            mb={componentStyle.mb}
            sx={styles.container}
        >
            <VStack sx={{ ...styles.formWrapper, alignSelf: componentStyle.alignSelf }}>
                {loadingState === LoadingState.ERROR ? (
                    <>
                        <Heading as={'h2'} my={4}>
                            {t('errorTitle')}
                        </Heading>
                        <Text maxW={'600px'}>{t('errorDescription')}</Text>
                    </>
                ) : (
                    <>
                        <Heading as={'h2'} hideBelow={'md'} sx={styles.headingLg}>
                            {translation.titleLg}
                        </Heading>
                        <Heading
                            as={'h2'}
                            hideFrom={'md'}
                            dangerouslySetInnerHTML={{ __html: translation.titleSm }}
                            sx={styles.headingSm}
                        />
                        <Text
                            fontSize={{
                                base: 'md',
                                xl: 'lg',
                            }}
                            maxW={'600px'}
                            dangerouslySetInnerHTML={{ __html: translation.description }}
                        />
                    </>
                )}
                {loadingState !== LoadingState.SUCCESS ? (
                    <>
                        <HStack
                            as={'form'}
                            data-testid="newsletter-form"
                            onSubmit={handleSubmit(onSubmit)}
                            sx={styles.form}
                        >
                            <Input
                                data-testid="newsletter-form-name-input"
                                placeholder={t('namePlaceholder')}
                                {...register('name')}
                                hideBelow={'md'}
                                flex={'1'}
                                sx={styles.formInput}
                            />
                            <Divider
                                orientation={'vertical'}
                                hideBelow={'md'}
                                sx={styles.formDivider}
                            />
                            <Box flex={'1'} pos={'relative'}>
                                <Input
                                    data-testid="newsletter-form-email-input"
                                    placeholder={t('emailPlaceholder')}
                                    {...register('email')}
                                    isInvalid={!!errors.email}
                                    sx={styles.formInput}
                                />
                                {errors.email ? (
                                    <Text
                                        as={'span'}
                                        sx={styles.emailErrorMsg}
                                        data-testid={'newsletter-validation-error-message'}
                                    >
                                        {typeof errors.email?.message === 'string'
                                            ? errors.email.message
                                            : null}
                                    </Text>
                                ) : null}
                            </Box>
                            <Button
                                type="submit"
                                data-testid="newsletter-form-submit-button"
                                colorScheme={buttonColorScheme}
                                variant={'solid'}
                                isDisabled={!!errors.email}
                                isLoading={loadingState === LoadingState.PENDING}
                                aria-label={t('submitButton')}
                            >
                                <IconsLinear.Add hideBelow={'md'} mr={2} />
                                <Text as={'span'} hideBelow={'md'}>
                                    {t('submitButton')}
                                </Text>
                                <IconsLinear.Send hideFrom={'md'} fontSize="2xl" />
                            </Button>
                        </HStack>
                        <Text
                            sx={{ ...styles.privacyPolicyTxt }}
                            dangerouslySetInnerHTML={{
                                __html: `${t('privacyPolicy', {
                                    privacyPolicySzallas: environment.CONSENTS_URLS.privacyPolicy,
                                })}`,
                            }}
                        />
                    </>
                ) : null}
            </VStack>

            {FT_USE_V7_THEME ? (
                <ChakraNextImage
                    src={imgSrc}
                    alt="sparkus"
                    width={296}
                    height={310}
                    sx={styles.image}
                />
            ) : null}
        </Flex>
    );
};
