import { Image } from '@chakra-ui/image';
import { Box, Heading, Text, VStack } from '@chakra-ui/layout';
import { useMultiStyleConfig, useTheme } from '@chakra-ui/system';

import { urlJoin } from 'url-join-ts';

import { GridWrapper } from '@nocowanie/common-ui';

import { ChakraNextImage } from '@app/components/atoms/chakra-next-image';
import { SectionNameEnum } from '@app/enums';
import { environment } from '@app/environments/environment';
import { HeroModel } from '@app/models/elastic-search';
import { homepageSelectors, useAppSelector } from '@app/state';

export const Hero = () => {
    const styles = useMultiStyleConfig('Hero', {});

    const heroData = useAppSelector(
        homepageSelectors.selectSectionData<HeroModel>(SectionNameEnum.HeroSection),
    );

    const imgSrc = urlJoin(environment.ASSETS_PATH, 'svg', 'hero-stars.svg');

    const theme = useTheme();
    const { sm, lg } = theme.breakpoints;

    if (!heroData) return;

    const {
        header,
        subheader,
        image: { sm: imgUrlSm, md: imgUrlMd, lg: imgUrlLg },
    } = heroData;

    return (
        <Box data-testid={'hero-wrapper'} sx={styles.wrapper}>
            <picture>
                <source media={`(min-width: ${lg})`} srcSet={imgUrlLg} />
                <source media={`(min-width: ${sm})`} srcSet={imgUrlMd} />
                <Image
                    src={imgUrlSm}
                    htmlWidth={480}
                    htmlHeight={510}
                    alt={''}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    loading={'eager'}
                    {...({ fetchpriority: 'high' } as React.HTMLAttributes<HTMLImageElement>)}
                />
            </picture>

            <GridWrapper
                templateColumns={{ base: '1fr' }}
                position={'absolute'}
                top={'50%'}
                left={'50%'}
                transform={'translate(-50%, -50%)'}
            >
                <VStack
                    justifyContent="center"
                    alignItems="left"
                    spacing={7}
                    my={{ base: 16, lg: 24, xl: 40 }}
                >
                    <Heading
                        position={'relative'}
                        as="h1"
                        size={'2xl'}
                        color="white"
                        maxW={{ base: '240px', sm: '440px' }}
                    >
                        {header}
                        <ChakraNextImage
                            hideBelow={'lg'}
                            position={'absolute'}
                            top={'-0.75em'}
                            right={'-0.65em'}
                            src={imgSrc}
                            alt=""
                            width={55}
                            height={65}
                        />
                    </Heading>
                    <Text
                        hideBelow={'sm'}
                        color="white"
                        fontSize={{ base: 'lg', md: '2xl' }}
                        maxW="450px"
                    >
                        {subheader}
                    </Text>
                </VStack>
            </GridWrapper>
        </Box>
    );
};
