export const staticLinks = [
    {
        url: 'https://weekend-majowy.nocowanie.pl/',
        label: 'Długi weekend majowy',
    },
    {
        url: 'https://sylwester.nocowanie.pl/',
        label: 'Sylwester',
    },
    {
        url: 'https://weekend-czerwcowy.nocowanie.pl/',
        label: 'Weekend czerwcowy',
    },
    {
        url: 'https://www.nocowanie.pl/noclegi/zakopane/',
        label: 'Zakopane',
    },
];
