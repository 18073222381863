import { Flex, Heading, Text } from '@chakra-ui/layout';

import { isNil } from 'lodash';

import { usePluralisationTranslation } from '@nocowanie/common-ui';
import { IconsLinear } from '@nocowanie/icons';

import { FeaturedImageWrapper } from '@app/components/molecules/featured-image-wrapper';

import { DestinationSuggestionItemProps } from './destination-suggestion-item.props';


export const DestinationSuggestionItem = ({
    label,
    accommodationsCount,
    image,
    path,
    ...rest
}: DestinationSuggestionItemProps) => {
    const pluralizeTranslation = usePluralisationTranslation('common', 'accommodationLabels');

    if (![label, accommodationsCount, image, path].every(item => !isNil(item))) return;

    const accommodationsLabel = pluralizeTranslation(accommodationsCount, 'accommodation');

    return (
        <FeaturedImageWrapper
            imgUrl={image}
            href={path}
            alt={label}
            data-testid="destination-suggestion-item-container"
            {...rest}
        >
            <Heading as={'h3'} fontSize={'2xl'} data-testid="destination-suggestion-item-header">
                {label}
            </Heading>
            <Flex alignItems={'center'} mt={1}>
                <IconsLinear.Flash
                    color={'selectiveYellow'}
                    fill={'selectiveYellow'}
                    size={'xs'}
                    mr={1}
                />
                <Text as="span" fontSize={'md'} data-testid="destination-suggestion-item-counter">
                    {accommodationsLabel}
                </Text>
            </Flex>
        </FeaturedImageWrapper>
    );
};
