import { useTranslation } from 'next-i18next';

import { Box } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';

import { AccommodationItem } from '@app/components/molecules/accommodation-item';
import { SectionCarousel } from '@app/components/organisms/section-carousel';
import { SectionNameEnum } from '@app/enums';
import { getFeaturedSubsectionIcon } from '@app/helpers';
import { FeaturedAccommodationsModel } from '@app/models/elastic-search';
import { commonUiStateSlice, homepageSelectors, useAppSelector } from '@app/state';

import { FeaturedAccommodationsProps } from './featured-accommodations.props';

export const FeaturedAccommodations = ({ ...rest }: FeaturedAccommodationsProps) => {
    const isMobileBrowser = useAppSelector(commonUiStateSlice.selectors.selectIsMobileVersion);
    const { t } = useTranslation('home', {
        keyPrefix: 'featuredAccommodations',
    });

    const featuredAccommodationsData = useAppSelector(
        homepageSelectors.selectSectionData<FeaturedAccommodationsModel>(
            SectionNameEnum.FeaturedAccommodationsSection,
        ),
    );

    const slidesElements = (featuredAccommodationsData?.subsections || []).map(subsection => ({
        name: subsection.label,
        slides: subsection.accommodations.map(accommodation => ({
            id: accommodation.id,
            body: (
                <AccommodationItem {...accommodation} data-testid="featured-accommodation-item" />
            ),
        })),
    }));

    const filters = (featuredAccommodationsData?.subsections || []).map(subsection => ({
        name: subsection.label,
        icon: (
            <Box data-testid="featured-accommodation-icon">
                {getFeaturedSubsectionIcon(subsection.icon, {
                    size: 'sm',
                })}
            </Box>
        ),
    }));

    const slidesCountBreakpoints = {
        base: 1,
        md: 2,
        lg: 3,
        xl: 4,
    };
    const breakpointFallback = isMobileBrowser ? 'base' : 'xl';

    const slidesPerView = useBreakpointValue(
        { ...slidesCountBreakpoints, base: 1.1, sm: 1.4 },
        {
            fallback: breakpointFallback,
        },
    );
    const slidesPerGroup = useBreakpointValue(slidesCountBreakpoints, {
        fallback: breakpointFallback,
    });

    const slideStyles = {
        'swiper-container': {
            display: 'flex',
            gap: 'gutterWidthDesktop',
        },
        'swiper-slide': {
            flexShrink: 0,
            width: {
                xl: 'calc(25% - 18px) !important', // On xl screens we have 4 slides, and we need to remove the gap between them
            },
        },
    };

    return (
        <Box data-testid={'featured-accommodations-wrapper'} sx={slideStyles} {...rest}>
            <SectionCarousel
                title={t('title')}
                subtitle={t('subTitle')}
                filters={filters}
                slideElements={slidesElements}
                sliderParams={{
                    spaceBetween: 24,
                    slidesPerView,
                    slidesPerGroup,
                }}
                sliderOverflow={'hidden'}
                isEdgeToEdgeOnMobile={true}
            />
        </Box>
    );
};
