import dynamic from 'next/dynamic';

import { Footer } from '@app/components/organisms/footer';

import { DefaultTemplateProps } from './default-template.props';

const ScrollTopButton = dynamic(() =>
        import('@app/components/atoms/scroll-top-button').then(
            c => c.ScrollTopButton,
        ),
    { ssr: false },
)

export const DefaultTemplate = ({
    stickyElement,
    isV7,
    showScrollTopButton,
    children,
}: DefaultTemplateProps) => {
    return (
        <>
            <main>{children}</main>
            <Footer />
            {showScrollTopButton ? <ScrollTopButton isV7={isV7} /> : null}
            {stickyElement ?? null}
        </>
    );
};
