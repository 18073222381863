import Link from 'next/link';

import { Box, Flex } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { useMultiStyleConfig, useTheme } from '@chakra-ui/system';

import { imageHelpers } from '@nocowanie/core';

import { FeaturedImageWrapperProps } from './featured-image-wrapper.props';

export const FeaturedImageWrapper = ({
    children,
    isWiderWidth,
    imgUrl,
    href,
    alt,
    ...rest
}: FeaturedImageWrapperProps) => {
    const styles = useMultiStyleConfig('FeaturedImageWrapper', {});
    const theme = useTheme();
    const baseWidth = 390;
    const widerWidth = 624;
    const baseHeight = 480;

    const imageSizes = {
        xl: isWiderWidth ? widerWidth : 300,
        lg: baseWidth,
        md: 460,
        sm: 510,
    };

    const breakpoints = Object.keys(imageSizes) as (keyof typeof imageSizes)[];

    const sourceData = breakpoints.map(breakpoint => ({
        size: imageSizes[breakpoint],
        breakpointValue: theme.breakpoints[breakpoint],
    }));

    return (
        <Flex sx={styles.container} {...rest}>
            <Flex sx={styles.linkWrapper} as={Link} href={href}>
                <Box as="picture" sx={styles.mask}>
                    {sourceData?.map((el, index) => (
                        <source
                            key={index}
                            srcSet={imageHelpers.wpresizer([el.size, baseHeight], imgUrl)}
                            media={`(min-width: ${el.breakpointValue})`}
                        />
                    ))}
                    <Image
                        src={imgUrl && imageHelpers.wpresizer([baseWidth, baseHeight], imgUrl)}
                        htmlWidth={baseWidth}
                        htmlHeight={baseHeight}
                        height={baseHeight}
                        loading="lazy"
                        sx={styles.image}
                        alt={alt}
                    />
                </Box>
            </Flex>
            <Flex sx={styles.contentWrapper}>{children}</Flex>
        </Flex>
    );
};
