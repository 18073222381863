import { Flex, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { usePluralisationTranslation } from '@nocowanie/common-ui';

import { getRatingDescValue } from '@app/helpers';

import { AverageRatingProps } from './average-rating.props';

export const AverageRating = ({
    ratingsAverage,
    ratingsCount,
    ratingDescription,
    ...rest
}: AverageRatingProps) => {
    const pluralizeTranslation = usePluralisationTranslation('common', 'accommodationLabels');
    const styles = useMultiStyleConfig('AverageRating', {});

    return (
        <Flex sx={styles.container} data-testid="average-rating-container" {...rest}>
            <Flex sx={styles.ratingNumerical} as={'span'} data-testid="average-rating-numerical">
                {ratingsAverage.toFixed(1)}
            </Flex>
            <Flex flexDirection={'column'}>
                <Text sx={styles.ratingDescription}>{getRatingDescValue(ratingDescription)}</Text>
                <Text sx={styles.ratingCount} as={'span'} data-testid="average-rating-count">
                    {pluralizeTranslation(ratingsCount, 'opinion')}
                </Text>
            </Flex>
        </Flex>
    );
};
