import { dateHelpers } from '@nocowanie/core';
import { GridWrapper } from '@nocowanie/common-ui';

import { SearchEngineWrapper } from '@app/components/molecules/search-engine-wrapper';
import { DestinationSuggestions } from '@app/components/organisms/destination-suggestions';
import { FeaturedAccommodations } from '@app/components/organisms/featured-accommodations';
import { FeaturedCountries } from '@app/components/organisms/featured-countries';
import { Hero } from '@app/components/organisms/hero';
import { Newsletter } from '@app/components/organisms/newsletter';
import { SeoLinksHomepage } from '@app/components/organisms/seo-links-homepage';
import { Support } from '@app/components/organisms/support';

export const Home = () => {
    return (
        <>
            <Hero />
            <GridWrapper
                templateColumns={{ base: 'minmax(0, 1fr)' }}
                rowGap={{
                    base: 'sectionGap',
                    md: 'sectionGapDesktop',
                }}
            >
                <SearchEngineWrapper
                    isMobileCompactVersion={false}
                    isV7SearchEngineVersion={true}
                    pos={'relative'}
                    marginTop={'-44px'}
                    initialSearchData={{
                        dates: {
                            checkInDate: dateHelpers.defaultCheckInDate(),
                            checkOutDate: dateHelpers.defaultCheckOutDate(),
                        },
                    }}
                />
                <DestinationSuggestions />
                <FeaturedAccommodations />
                <FeaturedCountries />
                <Newsletter />
            </GridWrapper>

            <SeoLinksHomepage
                py={{
                    base: 'sectionGap',
                    md: 'sectionGapDesktop',
                }}
            />
            <Support />
        </>
    );
};
