import { useTranslation } from 'next-i18next';

import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading, Link, Text, VStack } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { urlJoin } from 'url-join-ts';

import { urlHelpers, urlMap } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import { ChakraNextImage } from '@app/components/atoms/chakra-next-image';
import { environment } from '@app/environments/environment';

import { SupportProps } from './support.props';

export const Support = ({ ...props }: SupportProps) => {
    const { t } = useTranslation('common', {
        keyPrefix: 'support',
    });

    const touristInfoItems = {
        userType: t('iAmTourist'),
        email: 'info@nocowanie.pl',
        phoneNumber: '22 116 82 96',
        openingDays: t('openingDays'),
        openingHours: '8:00 - 16:00',
    };

    const styles = useMultiStyleConfig('Support', {});
    const buttonColorScheme = styles.buttonColorScheme as string;

    const bgSrc = urlJoin(environment.ASSETS_PATH, '/svg/bg-support-2.svg');

    return (
        <Box sx={styles.bgWrapper} {...props}>
            <Flex sx={styles.container}>
                <Flex sx={styles.contactInfoBox}>
                    <Heading fontSize={'2xl'} mb={6}>
                        {t('supportHeader')}
                    </Heading>
                    <Text
                        hideBelow={'md'}
                        dangerouslySetInnerHTML={{ __html: `${t('supportSubHeader')}` }}
                        sx={styles.subHeader}
                    />
                    <Flex sx={styles.userTypesWrapper}>
                        <VStack sx={styles.userTypeBox}>
                            <Text
                                mb={2}
                                fontSize={'lg'}
                                dangerouslySetInnerHTML={{ __html: t('iAmHost') }}
                            />
                            <Button
                                as={'a'}
                                target="_blank"
                                href={urlMap.partnerContactForm}
                                colorScheme={'secondary'}
                            >
                                {t('fillInForm')}
                            </Button>
                        </VStack>
                        <VStack sx={styles.userTypeBox} justifyContent={'space-between'}>
                            <Text
                                mb={2}
                                fontSize={'lg'}
                                dangerouslySetInnerHTML={{ __html: touristInfoItems.userType }}
                            />
                            <Flex alignItems={'center'} gap={2} mb={2}>
                                <IconsLinear.SmsTracking fontSize={'xl'} />
                                <Link
                                    href={`mailto:${touristInfoItems.email}`}
                                    sx={styles.emailLink}
                                >
                                    {touristInfoItems.email}
                                </Link>
                            </Flex>
                            <Flex direction={'column'}>
                                <Flex gap={2} alignItems={'center'}>
                                    <IconsLinear.Call2 fontSize={'xl'} />
                                    <Link
                                        href={urlHelpers.getPhoneNoHref(
                                            touristInfoItems.phoneNumber,
                                        )}
                                        fontSize={'md'}
                                    >
                                        {touristInfoItems.phoneNumber}
                                    </Link>
                                </Flex>
                                <Text sx={styles.hotlineAvailability}>
                                    {touristInfoItems.openingDays}, {touristInfoItems.openingHours}
                                </Text>
                            </Flex>
                        </VStack>
                    </Flex>
                </Flex>
                <Flex sx={styles.checkOurOfferBox}>
                    <ChakraNextImage
                        src={bgSrc}
                        alt="home image"
                        width={280}
                        height={156}
                        sx={styles.image}
                    />
                    <Text
                        dangerouslySetInnerHTML={{ __html: `${t('addAccommodation')}` }}
                        fontWeight={'600'}
                        fontSize={'xl'}
                    />
                    <Button
                        as={'a'}
                        target="_blank"
                        href={`/${urlMap.join}`}
                        colorScheme={buttonColorScheme}
                        variant={'solid'}
                    >
                        {t('checkOurOffer')}
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
};
