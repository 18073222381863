import { useTranslation } from 'next-i18next';

import { Box, Flex, Link, Text } from '@chakra-ui/react';

import { IconProps, IconsNocowanie } from '@nocowanie/icons';

import { SocialMediaProps } from './social-media.props';
import { defaultSocialMediaTranslation } from './social-media.translation.model';

export const SocialMedia = ({
    translationData = defaultSocialMediaTranslation,
    ...props
}: SocialMediaProps) => {
    const { t } = useTranslation('socialMedia');

    const iconConfig: IconProps = {
        fontSize: '2xl',
    };

    const SocialMediaData = [
        {
            url: t('facebook'),
            icon: <IconsNocowanie.Facebook {...iconConfig} />,
        },
        {
            url: t('youtube'),
            icon: <IconsNocowanie.Youtube {...iconConfig} />,
        },
        {
            url: t('instagram'),
            icon: <IconsNocowanie.Instagram {...iconConfig} />,
        },
    ];

    return (
        <Box {...props}>
            <Text
                fontSize={{
                    md: 'xl',
                }}
            >
                {translationData.followUs}
            </Text>
            <Flex
                mt={{
                    base: 3,
                    md: 4,
                }}
                gap={6}
            >
                {SocialMediaData.map((item, index) => (
                    <Link
                        href={item.url}
                        key={index}
                        target="_blank"
                        rel="nofollow noopener"
                        p={3}
                        border={'1px solid #ffff'}
                        borderRadius={'full'}
                        _hover={{
                            bg: '#ffff',
                            color: 'gray.900',
                            transition: 'all 0.3s ease-in-out',
                        }}
                    >
                        {item.icon}
                    </Link>
                ))}
            </Flex>
        </Box>
    );
};
