import { Box, Container, Flex, Link, Text, VStack } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { dateHelpers } from '@nocowanie/core';

import { SocialMedia } from '@app/components/molecules/social-media';

import { FooterProps } from './footer.props';
import menuLinks from './menu-links.json';
import { staticLinks } from './static-links-data';

export const Footer = ({ ...rest }: FooterProps) => {
    const styles = useMultiStyleConfig('Footer', {});

    return (
        <Box as={'footer'} sx={styles.wrapper} {...rest}>
            <Container
                maxWidth={'containerWidth'}
                px={{
                    base: 'gutterWidth',
                    md: 'gutterWidthDesktop',
                }}
                py={{
                    base: 6,
                    md: 12,
                }}
            >
                <SocialMedia hideFrom="md" mb={9} />
                <Flex gap={2}>
                    <Box
                        w="full"
                        sx={{
                            columnCount: { base: 1, md: 3 },
                        }}
                    >
                        {menuLinks.map((link, index) => (
                            <VStack key={index} mb={4} alignItems={'flex-start'}>
                                <Link href={link.url} target={link.target ?? '_self'}>
                                    {link.label}
                                </Link>
                            </VStack>
                        ))}
                    </Box>
                    <SocialMedia hideBelow="md" w={'25%'} />
                </Flex>
                <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    fontSize={'sm'}
                    mt={{
                        base: 4,
                        md: 12,
                    }}
                    gap={8}
                >
                    <Flex flexWrap={'wrap'} gap={1}>
                        {staticLinks.map((link, index) => (
                            <Box key={index}>
                                <Link href={link.url}>{link.label}</Link>
                                {index < staticLinks.length - 1 && ','}
                            </Box>
                        ))}
                    </Flex>
                    <Text>
                        Copyright 2005-{dateHelpers.format(new Date(), 'yyyy')}{' '}
                        <Box as="br" hideFrom={'md'} />
                        by NOCOWANIE.PL Sp. z o.o.
                    </Text>
                </Flex>
            </Container>
        </Box>
    );
};
