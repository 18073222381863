import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';

import { DataLayerPageTypeEnum, FeatureToggleEnum } from '@nocowanie/core';

import { MetaTags } from '@app/components/atoms/meta-tags';
import { Home } from '@app/components/pages/home';
import { DefaultTemplate } from '@app/components/templates/default-template';
import { usePageDataLayerEvents } from '@app/hooks';
import { PageProps } from '@app/models';
import { serverSidePropsHandler } from '@app/pages/index.server-side-props.handler';
import { commonUiStateSlice, nocowanieAppStateWrapper, useAppSelector } from '@app/state';

const HomePage: NextPage<PageProps> = props => {
    nocowanieAppStateWrapper.useHydration(props);

    usePageDataLayerEvents({
        pageType: DataLayerPageTypeEnum.Home,
    });

    const { t } = useTranslation('home', {
        keyPrefix: 'metaTags',
    });

    const USE_V7_THEME = useAppSelector(
        commonUiStateSlice.selectors.selectUIFtActive<boolean>(FeatureToggleEnum.USE_V7_THEME),
    );

    return (
        <>
            <MetaTags description={t('description')} title={t('title')} titleSuffix={''} />
            <DefaultTemplate isV7={USE_V7_THEME} showScrollTopButton={!!props.showScrollTopButton}>
                <Home />
            </DefaultTemplate>
        </>
    );
};

export const getServerSideProps = serverSidePropsHandler;

export default HomePage;
