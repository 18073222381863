import { SlideProps } from '@nocowanie/common-ui';

type SectionCarouselState = {
    activeFilter: string;
    slides: SlideProps[];
    activePage: number;
    totalPages: number;
    totalSlides: number;
    isPrevDisabled: boolean;
    isNextDisabled: boolean;
};

export const ACTION_TYPES = {
    SET_FILTER: 'SET_FILTER',
    UPDATE_PAGINATION: 'UPDATE_PAGINATION',
};

type FilterPayload = {
    filterName: string;
    slides: SlideProps[];
    totalSlides: number;
    totalPages: number;
    activePage: number;
};

type PaginationPayload = {
    currentPage: number;
    totalPages: number;
    isPrevDisabled: boolean;
    isNextDisabled: boolean;
};

type SectionCarouselAction =
    | { type: typeof ACTION_TYPES.SET_FILTER; payload: FilterPayload }
    | { type: typeof ACTION_TYPES.UPDATE_PAGINATION; payload: PaginationPayload };

const setFilter = (state: SectionCarouselState, payload: FilterPayload): SectionCarouselState => {
    return {
        ...state,
        activeFilter: payload.filterName,
        slides: payload.slides,
        totalSlides: payload.totalSlides,
        totalPages: payload.totalPages,
        activePage: payload.activePage,
        isPrevDisabled: true,
        isNextDisabled: payload.totalPages === 1,
    };
};

const updatePagination = (
    state: SectionCarouselState,
    payload: PaginationPayload,
): SectionCarouselState => {
    return {
        ...state,
        activePage: payload.currentPage,
        totalPages: payload.totalPages,
        isPrevDisabled: payload.isPrevDisabled,
        isNextDisabled: payload.isNextDisabled,
    };
};

const actionHandlers = {
    [ACTION_TYPES.SET_FILTER]: setFilter,
    [ACTION_TYPES.UPDATE_PAGINATION]: updatePagination,
};

export const sectionCarouselReducer = (
    state: SectionCarouselState,
    action: SectionCarouselAction,
): SectionCarouselState => {
    const handler = actionHandlers[action.type];

    return handler ? handler(state, action.payload as FilterPayload & PaginationPayload) : state;
};
