import Link from 'next/link';

import { Flex, Heading, HStack, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import { useMultiStyleConfig, useTheme } from '@chakra-ui/system';

import { isNil } from 'lodash';

import { usePluralisationTranslation } from '@nocowanie/common-ui';
import { IconsLinear } from '@nocowanie/icons';

import { FeaturedImageWrapper } from '@app/components/molecules/featured-image-wrapper';

import { FeaturedCountryItemProps } from './featured-country-item.props';

export const FeaturedCountryItem = ({
    name,
    accommodationsCount,
    image,
    path,
    cities,
    isHighlighted = false,
    ...rest
}: FeaturedCountryItemProps) => {
    const pluralizeTranslation = usePluralisationTranslation('common', 'accommodationLabels');
    const theme = useTheme();
    const { xl } = theme.breakpoints;
    const [isLargerThanXl] = useMediaQuery(`(min-width: ${xl})`);
    const isWideImg = isHighlighted && isLargerThanXl;
    const styles = useMultiStyleConfig('FeaturedCountryItem', {});

    if (![name, accommodationsCount, image, path, cities].every(item => !isNil(item))) return;

    const accommodationsLabel = pluralizeTranslation(accommodationsCount, 'accommodation');

    return (
        <FeaturedImageWrapper
            imgUrl={image}
            href={path}
            isWiderWidth={isWideImg}
            alt={name}
            data-testid="featured-country-item-container"
            {...rest}
        >
            <HStack alignItems={'baseline'}>
                <Heading as={'h3'} fontSize={'2xl'} data-testid="featured-country-item-header">
                    {name}
                </Heading>
                <IconsLinear.Flash
                    alignSelf={'center'}
                    color={'selectiveYellow'}
                    fill={'selectiveYellow'}
                    size={'xs'}
                    ml={{
                        base: 1,
                        md: 2,
                    }}
                />
                <Text as="span">{accommodationsLabel}</Text>
            </HStack>
            <Flex sx={styles.links} data-testid="featured-country-item-links">
                {cities.map((city, index) => (
                    <Link key={index} href={city.path} data-testid="featured-country-item-link">
                        {city.name}
                    </Link>
                ))}
            </Flex>
        </FeaturedImageWrapper>
    );
};
