import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';

import { Image } from '@chakra-ui/image';
import { Badge, Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import qs from 'qs';
import { urlJoinP } from 'url-join-ts';

import { dateHelpers, formatHelpers, imageHelpers } from '@nocowanie/core';
import { usePluralisationTranslation } from '@nocowanie/common-ui';

import { AverageRating } from '@app/components/atoms/average-rating';
import { environment } from '@app/environments/environment';

import { AccommodationItemProps } from './accommodation-item.props';

export const AccommodationItem = ({
    name,
    settlementName,
    images,
    distanceFromCityCentre,
    path,
    ratingsCount,
    ratingsAverage,
    ratingDescription,
    cheapestRate: { rate, baseRate, guests, children, duration, currency },
    ...rest
}: AccommodationItemProps) => {
    const { t } = useTranslation('common', { keyPrefix: 'accommodationLabels' });
    const defaultImage = images.find(image => image.default);
    const pluralizeTranslation = usePluralisationTranslation('common', 'accommodationLabels');
    const hasDiscount = rate < baseRate;
    const discount = hasDiscount ? Math.floor(((baseRate - rate) / baseRate) * 100) : undefined;
    const nightsLabel = pluralizeTranslation(duration, 'night');
    const valueToPluralize = children && children > 0 ? children : guests;
    const totalGuestsLabel = pluralizeTranslation(
        valueToPluralize,
        'person',
        `${guests}${children ? ` + ${children}` : ''}`,
    );

    const accomodationHref = useMemo(() => {
        const params = {
            checkInDate: dateHelpers.format(
                dateHelpers.defaultCheckInDate(),
                environment.API_DATE_FORMAT,
            ),
            checkOutDate: dateHelpers.format(
                dateHelpers.defaultCheckOutDate(),
                environment.API_DATE_FORMAT,
            ),
            adultsCount: guests?.toString() || '1',
            childrenCount: children ? children.toString() : '0',
        };
        const urlParams = qs.stringify(params, { arrayFormat: 'indices' });
        return urlJoinP(`/${path}`, [`?${urlParams}`]);
    }, [path, guests, children, duration]);

    const styles = useMultiStyleConfig('AccommodationItem', {});
    const discountColorScheme = styles?.discountBadge?.colorScheme as string;

    return (
        <Flex sx={styles.container} data-testid="accommodation-item-container" {...rest}>
            {defaultImage?.url ? (
                <Image
                    src={defaultImage.url && imageHelpers.wpresizer([300, 195], defaultImage.url)}
                    loading="lazy"
                    sx={styles.image}
                    alt={name}
                    data-testid="accommodation-item-image"
                />
            ) : null}

            <Flex sx={styles.contentWrapper}>
                <Flex flexDirection={'column'} sx={styles.accommodationInfoWrapper}>
                    <Heading as={'h3'} sx={styles.heading} data-testid="accommodation-item-header">
                        <Box
                            as={Link}
                            sx={styles.link}
                            href={accomodationHref}
                            data-testid="accommodation-item-link"
                        >
                            {name}
                        </Box>
                    </Heading>
                    <Flex sx={styles.settlementNameContainer}>
                        <Text as={'span'} fontWeight={'bold'}>
                            {settlementName}
                        </Text>
                        {distanceFromCityCentre ? (
                            <Text as={'span'} color={'gray.500'}>
                                {distanceFromCityCentre} {t('distanceFromCityCentre')}
                            </Text>
                        ) : null}
                    </Flex>
                </Flex>
                <Flex sx={styles.cheapestRateAndRattingWrapper}>
                    {ratingsCount && ratingsAverage && ratingDescription ? (
                        <AverageRating
                            ratingsAverage={ratingsAverage}
                            ratingsCount={ratingsCount}
                            ratingDescription={ratingDescription}
                        />
                    ) : null}
                    <Flex sx={styles.cheapestRateWrapper}>
                        <Box sx={styles.discountBadgeWrapper}>
                            {hasDiscount ? (
                                <Badge
                                    variant={'solid'}
                                    size={'sm'}
                                    fontSize={'xs'}
                                    colorScheme={discountColorScheme}
                                    data-testid="accommodation-item-discount-value"
                                >
                                    -{discount}%
                                </Badge>
                            ) : null}
                        </Box>
                        <Text fontWeight={'bold'} data-testid="accommodation-item-rate">
                            {formatHelpers.price(rate, currency)}
                        </Text>
                        <Text
                            as={'span'}
                            fontSize={'xs'}
                            data-testid="accommodation-item-guests-nights"
                        >
                            {totalGuestsLabel}, {nightsLabel}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
