import { useTranslation } from 'next-i18next';

import { Box } from '@chakra-ui/layout';

import { GridWrapper, SeoLinks } from '@nocowanie/common-ui';

import { SectionNameEnum } from '@app/enums';
import { PopularTravelDestinationsModel } from '@app/models/elastic-search';
import { homepageSelectors, useAppSelector } from '@app/state';

import { SeoLinksHomepageProps } from './seo-links-homepage.props';

export const SeoLinksHomepage = ({ ...props }: SeoLinksHomepageProps) => {
    const popularTravelDestinations = useAppSelector(
        homepageSelectors.selectSectionData<PopularTravelDestinationsModel>(
            SectionNameEnum.PopularTravelDestinations,
        ),
    );
    const { t } = useTranslation('home', {
        keyPrefix: 'seoLinks',
    });

    if (!popularTravelDestinations?.length) {
        return;
    }
    return (
        <Box bg={'gray.25'} data-testid={'seo-links-wrapper'} {...props}>
            <GridWrapper templateColumns={{ base: '1fr' }}>
                <SeoLinks title={t('title')} linkSections={popularTravelDestinations} />
            </GridWrapper>
        </Box>
    );
};
