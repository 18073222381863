import { useTranslation } from 'next-i18next';

import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { Button } from '@chakra-ui/button';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Heading, HStack, Text } from '@chakra-ui/layout';
import { Show } from '@chakra-ui/media-query';
import { useTheme } from '@chakra-ui/system';

import debounce from 'lodash/debounce';

import { ScrollableWrapper, SlideProps, Slider } from '@nocowanie/common-ui';

import { commonUiStateSlice, useAppSelector } from '@app/state';

import { SectionCarouselProps, SlideGroup } from './section-carousel.props';
import { ACTION_TYPES, sectionCarouselReducer } from './section-carousel.state';

const isSlideGroup = (element: SlideProps | SlideGroup): element is SlideGroup => {
    return (element as SlideGroup)?.slides !== undefined;
};

export const SectionCarousel = ({
    title,
    subtitle,
    filters,
    slideElements,
    sliderParams,
    slideHeight,
    sliderOverflow,
    isEdgeToEdgeOnMobile,
}: SectionCarouselProps) => {
    const { t } = useTranslation('common', {
        keyPrefix: 'sectionCarousel',
    });
    const isMobile = useAppSelector(commonUiStateSlice.selectors.selectIsMobileVersion);
    const theme = useTheme();
    const gutterWidth = theme.space['gutterWidth'];

    const adjustSlideGutters = (slide: SlideProps) => ({
        ...slide,
        body: isEdgeToEdgeOnMobile ? (
            <Box px={isEdgeToEdgeOnMobile && isMobile ? gutterWidth : 0}>{slide.body}</Box>
        ) : (
            slide.body
        ),
    });

    const slides = useMemo(() => {
        return (slideElements || []).map(element => {
            if (isSlideGroup(element)) {
                return {
                    ...element,
                    slides: element.slides.map(adjustSlideGutters),
                };
            }
            return adjustSlideGutters(element as SlideProps);
        });
    }, [slideElements, gutterWidth, isEdgeToEdgeOnMobile, isMobile]);

    const initialSlides = useMemo(() => {
        if (Array.isArray(slides) && isSlideGroup(slides[0])) {
            return slides[0].slides;
        }
        return slides as SlideProps[];
    }, [slides]);

    const slidesPerView =
        sliderParams.slidesPerView && sliderParams.slidesPerView !== 'auto'
            ? sliderParams.slidesPerView
            : 1;

    const slidesPerGroup = sliderParams.slidesPerGroup ?? 1;

    const totalPages = Math.ceil(initialSlides.length / slidesPerGroup);

    const [state, dispatch] = useReducer(sectionCarouselReducer, {
        activeFilter: filters?.[0]?.name || '',
        slides: initialSlides,
        activePage: 1,
        totalPages,
        totalSlides: initialSlides.length,
        isPrevDisabled: true,
        isNextDisabled: totalPages <= 1,
    });

    const swiperRef = useRef<HTMLElement>(null);
    const totalSlidesRef = useRef(state.totalSlides);

    useEffect(() => {
        totalSlidesRef.current = state.totalSlides;
    }, [state.totalSlides]);

    const swiperInstance = useCallback(() => {
        return swiperRef.current ? (swiperRef.current as any).swiper : null;
    }, []);

    const scrollToFilter = (el: HTMLButtonElement) => {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    };

    const handleFilterChange = useCallback(
        (el: HTMLButtonElement, filterName: string) => {
            swiperInstance()?.slideTo(0);
            const currentSlideGroup = slides.find(
                el => isSlideGroup(el) && el.name === filterName,
            ) as SlideGroup;

            if (isSlideGroup(currentSlideGroup)) {
                const totalSlides = currentSlideGroup.slides.length;
                const totalPages = Math.ceil(totalSlides / slidesPerGroup);

                dispatch({
                    type: ACTION_TYPES.SET_FILTER,
                    payload: {
                        filterName: currentSlideGroup.name,
                        slides: currentSlideGroup.slides,
                        totalSlides,
                        totalPages,
                        activePage: 1,
                    },
                });
            }

            if (isMobile) {
                scrollToFilter(el);
            }
        },
        [swiperInstance, slidesPerGroup, slides, isMobile],
    );

    const handlePrev = debounce(() => {
        swiperInstance()?.slidePrev();
    }, 200);

    const handleNext = debounce(() => {
        swiperInstance()?.slideNext();
    }, 200);

    const updatePagination = useCallback(() => {
        const swiper = swiperInstance();

        if (swiper) {
            const currentPage = Math.ceil((swiper.realIndex + slidesPerGroup) / slidesPerGroup);
            const totalPages = Math.ceil(totalSlidesRef.current / slidesPerGroup);
            const isPrevDisabled = currentPage <= 1;
            const isNextDisabled = currentPage >= totalPages;

            dispatch({
                type: ACTION_TYPES.UPDATE_PAGINATION,
                payload: { currentPage, totalPages, isPrevDisabled, isNextDisabled },
            });
        }
    }, [swiperInstance, slidesPerGroup]);

    useEffect(() => {
        updatePagination();
    }, [totalPages, updatePagination]);

    useEffect(() => {
        const swiper = swiperInstance();

        if (swiper) {
            swiper.on('transitionEnd', updatePagination);

            return () => {
                swiper.off('transitionEnd', updatePagination);
            };
        }
    }, [slidesPerView, swiperInstance, slidesPerGroup, updatePagination]);

    const renderFilters = filters?.map((filter, index) => (
        <Button
            key={filter.name}
            variant={filter.name === state.activeFilter ? 'filterItemActive' : 'filterItem'}
            size={'md'}
            minW={'max-content'}
            onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
                handleFilterChange(ev.currentTarget, filter.name)
            }
            ml={index === 0 && isEdgeToEdgeOnMobile && isMobile ? gutterWidth : 0}
        >
            <Box as="span" mr={2}>
                {filter.icon}
            </Box>{' '}
            {filter.name}
        </Button>
    ));

    return (
        <Box mx={isEdgeToEdgeOnMobile && isMobile ? `-${gutterWidth}` : 0}>
            <Box ml={isEdgeToEdgeOnMobile && isMobile ? gutterWidth : 0}>
                <Heading as={'h2'} fontSize={'2xl'}>
                    {title}
                </Heading>
                {subtitle && !isMobile ? (
                    <Text fontSize={'sm'} mt={2}>
                        {subtitle}
                    </Text>
                ) : null}
            </Box>
            {filters ? (
                <>
                    {isMobile ? (
                        <ScrollableWrapper
                            mt={2}
                            pb={2}
                            w={{ base: isEdgeToEdgeOnMobile ? 'auto' : '100vw', md: 'max-content' }}
                        >
                            {renderFilters}
                        </ScrollableWrapper>
                    ) : (
                        <HStack mt={6} overflowX={'auto'}>
                            {renderFilters}
                        </HStack>
                    )}
                </>
            ) : null}

            {state.slides ? (
                <Box
                    mt={(!subtitle && !filters) || isMobile ? 6 : 8}
                    position={'relative'}
                    mr={isMobile ? `-${gutterWidth}` : undefined}
                    maxW={'100%'}
                >
                    <Show above="md">
                        <HStack
                            justify={'space-between'}
                            align={'center'}
                            gap={0}
                            pos={'absolute'}
                            right={0}
                            top={-12}
                        >
                            <Button
                                onClick={handlePrev}
                                variant={'link'}
                                color={'black'}
                                isDisabled={state.isPrevDisabled}
                                aria-label={t('paginationPrevLabel')}
                            >
                                <ChevronLeftIcon boxSize={6} />
                            </Button>
                            <Text as={'span'}>
                                {state.activePage} / {state.totalPages}
                            </Text>
                            <Button
                                onClick={handleNext}
                                variant={'link'}
                                color={'black'}
                                isDisabled={state.isNextDisabled}
                                aria-label={t('paginationNextLabel')}
                            >
                                <ChevronRightIcon boxSize={6} />
                            </Button>
                        </HStack>
                    </Show>
                    <Slider
                        ref={swiperRef}
                        slides={state.slides}
                        sliderParams={{
                            ...sliderParams,
                            spaceBetween:
                                isEdgeToEdgeOnMobile && isMobile ? 0 : sliderParams.spaceBetween,
                        }}
                        slideHeight={slideHeight}
                        sliderOverflow={sliderOverflow}
                    />
                </Box>
            ) : null}
        </Box>
    );
};
