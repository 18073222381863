import { object, string } from 'yup';

import { RegexConst } from '@nocowanie/core';

export const getSchema = (t: (key: string) => string) => {
    const noEmail = t('validation.noEmail');
    const incorrectEmail = t('validation.incorrectEmail');

    return object().shape({
        name: string(),
        email: string()
            .trim()
            .required(noEmail)
            .email(incorrectEmail)
            .matches(RegexConst.EMAIL_ADDRESS, {
                message: incorrectEmail,
            }),
    });
};
