import Head from 'next/head';
import { useTranslation } from 'next-i18next';

import { FeatureToggleEnum } from '@nocowanie/core';

import { environment } from '@app/environments/environment';
import { getAssetUrl } from '@app/helpers/ssr.helpers';
import { commonUiStateSlice, useAppSelector } from '@app/state';

import { MetaTagsProps } from './meta-tags.props';

export const MetaTags = ({
    description,
    title,
    imgUrl,
    noIndex = false,
    showTwitterMetaTags = false,
    showCanonicalUrl = false,
    titleSuffix = ' - Nocowanie.pl',
    url = 'https://www.nocowanie.pl',
}: MetaTagsProps) => {
    const { t } = useTranslation('common', {
        keyPrefix: 'metaTags',
    });
    const FT_USE_V7_THEME = useAppSelector(
        commonUiStateSlice.selectors.selectUIFtActive<boolean>(FeatureToggleEnum.USE_V7_THEME),
    );

    const domain = 'Nocowanie.pl';
    const metaTitle = `${title ?? t('defaultTitle')}${titleSuffix}`;
    const metaDescription = description ?? t('defaultDescription');
    const defaultImageUrl = getAssetUrl(FT_USE_V7_THEME ? 'logo.png' : 'logo-nocowanie.png');

    return (
        <Head>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            {url && showCanonicalUrl ? <link rel="canonical" href={url} /> : null}
            {noIndex ? (
                <meta name="robots" content="noindex" />
            ) : (
                <meta name="robots" content="index,follow,all" />
            )}

            {/* Facebook Meta Tags */}
            <meta property="fb:app_id" content={environment.FACEBOOK_APP_ID} />
            <meta property="og:site_name" content={domain} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:locale" content="pl_PL" />
            {url ? <meta property="og:url" content={url} /> : null}
            {imgUrl ? (
                <meta property="og:image" content={imgUrl} />
            ) : (
                <>
                    <meta property="og:image" content={defaultImageUrl} />
                    <meta property="og:image:alt" content={t('logoAlt')} />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:image:type" content="image/png" />
                </>
            )}

            {showTwitterMetaTags ? (
                <>
                    {/* Twitter Meta Tags*/}
                    <meta property="twitter:domain" content={domain} />
                    <meta name="twitter:title" content={metaTitle} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description" content={metaDescription} />
                    {url ? <meta property="twitter:url" content={url} /> : null}
                    {imgUrl ? <meta name="twitter:image" content={imgUrl} /> : null}
                </>
            ) : null}
        </Head>
    );
};
