import { useTranslation } from 'next-i18next';

import { Box } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';

import { v4 as uuid } from 'uuid';

import { FeaturedCountryItem } from '@app/components/molecules/featured-country-item';
import { SectionCarousel } from '@app/components/organisms/section-carousel';
import { SectionNameEnum } from '@app/enums';
import { FeaturedCountriesModel } from '@app/models/elastic-search';
import { commonUiStateSlice, homepageSelectors, useAppSelector } from '@app/state';

import { FeaturedCountriesProps } from './featured-countries.props';

const slideStyles = {
    'swiper-container': {
        display: 'flex',
        gap: 'gutterWidthDesktop',
    },
    'swiper-slide': {
        flexShrink: 0,
        width: {
            xl: '300px !important',
        },
    },
    'swiper-slide:nth-of-type(3n + 1)': {
        width: {
            xl: 'calc(100% - 648px) !important', // 648 -> 2x slide width + 2x gutter width,
        },
    },
};

export const FeaturedCountries = ({ ...rest }: FeaturedCountriesProps) => {
    const isMobileBrowser = useAppSelector(commonUiStateSlice.selectors.selectIsMobileVersion);
    const { t } = useTranslation('home', {
        keyPrefix: 'featuredCountries',
    });

    const featuredCountriesData = useAppSelector(
        homepageSelectors.selectSectionData<FeaturedCountriesModel>(
            SectionNameEnum.FeaturedCountriesSection,
        ),
    );

    const slidesElements = featuredCountriesData?.countries?.map((country, index) => ({
        id: uuid(),
        body: <FeaturedCountryItem {...country} isHighlighted={index % 3 === 0} />,
    }));

    const slidesCountBreakpoints = {
        base: 1,
        md: 2,
        lg: 3,
    };

    const slidesPerView = useBreakpointValue(
        {
            ...slidesCountBreakpoints,
            base: 1.1,
            sm: 1.4,
        },
        {
            fallback: isMobileBrowser ? 'base' : 'xl',
        },
    );
    const slidesPerGroup = useBreakpointValue(slidesCountBreakpoints);

    return (
        <Box data-testid={'featured-countries-wrapper'} sx={slideStyles} {...rest}>
            <SectionCarousel
                title={t('title')}
                slideHeight={480}
                slideElements={slidesElements}
                sliderParams={{
                    spaceBetween: 24,
                    slidesPerView,
                    slidesPerGroup,
                }}
                sliderOverflow={'hidden'}
                isEdgeToEdgeOnMobile={true}
            />
        </Box>
    );
};
