import { useTranslation } from 'next-i18next';

import { useMemo, useState } from 'react';

import { useMultiStyleConfig } from '@chakra-ui/system';

import axios from 'axios';
import { urlJoinP } from 'url-join-ts';

import { LoadingState } from '@nocowanie/core';

import { NewsletterUi } from '@app/components/organisms/newsletter/newsletter.types';
import { NewsletterFormModel } from '@app/components/organisms/newsletter/newsletter-form.model';
import { SalesManagoTag } from '@app/enums';
import { environment } from '@app/environments/environment';

export const useNewsletterApi = (tag: SalesManagoTag) => {
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IDLE);

    const onSubmit = async ({ email, name }: NewsletterFormModel): Promise<void> => {
        setLoadingState(LoadingState.PENDING);

        try {
            await axios
                .post(urlJoinP(environment.SSR_API_HOST, ['sm', 'newsletter', '/']), {
                    name,
                    email,
                    tag,
                })
                .then(res => {
                    const {
                        data: {
                            data: { success },
                        },
                    } = res;
                    if (success) {
                        setLoadingState(LoadingState.SUCCESS);
                    } else {
                        setLoadingState(LoadingState.ERROR);
                    }
                });
        } catch (error) {
            console.error('Error submitting newsletter:', error);
            setLoadingState(LoadingState.ERROR);
        }
    };

    return {
        loadingState,
        onSubmit,
    };
};

export const useNewsletterUi = (loadingState: LoadingState): NewsletterUi => {
    const { t } = useTranslation('home', { keyPrefix: 'newsletter' });
    const styles = useMultiStyleConfig('Newsletter', {});

    const { success, error, initial } = styles.bgColors as {
        success: string;
        error: string;
        initial: string;
    };

    return useMemo<NewsletterUi>(() => {
        return {
            componentStyle:
                loadingState === LoadingState.SUCCESS
                    ? {
                          bg: success,
                          mb: { base: 4, md: undefined },
                          alignSelf: 'end',
                      }
                    : loadingState === LoadingState.ERROR
                      ? {
                            bg: error,
                            alignSelf: 'center',
                        }
                      : {
                            bg: initial,
                            alignSelf: 'center',
                        },
            translation: t(loadingState === LoadingState.SUCCESS ? 'successStatus' : 'idleStatus', {
                returnObjects: true,
            }) as NewsletterUi['translation'],
        };
    }, [loadingState]);
};
