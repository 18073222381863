import { useTranslation } from 'next-i18next';

import { useCallback } from 'react';

import { Box } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { useTheme } from '@chakra-ui/system';

import { DestinationSuggestionItem } from '@app/components/molecules/destination-suggestion-item';
import { SectionCarousel } from '@app/components/organisms/section-carousel';
import { SectionNameEnum } from '@app/enums';
import { getFeaturedSubsectionIcon } from '@app/helpers';
import { DestinationSuggestionsModel } from '@app/models/elastic-search';
import { commonUiStateSlice, homepageSelectors, useAppSelector } from '@app/state';

export const DestinationSuggestions = () => {
    const isMobileBrowser = useAppSelector(commonUiStateSlice.selectors.selectIsMobileVersion);
    const { t } = useTranslation('home', {
        keyPrefix: 'destinationSuggestions',
    });

    const destinationSuggestionsData = useAppSelector(
        homepageSelectors.selectSectionData<DestinationSuggestionsModel>(
            SectionNameEnum.DestinationSuggestionsSection,
        ),
    );

    const theme = useTheme();
    const gutterWidthDesktop = theme.space['gutterWidthDesktop'];

    const getSlideWidth = useCallback(
        (x: number) => {
            // we need to calculate the appropriate margin factor value depending on the number of slides
            const marginFactor = Math.round((1 - 1 / x) * 1000) / 1000;

            return isMobileBrowser
                ? `calc(100% / ${x}) !important`
                : `calc(100% / ${x} - ${gutterWidthDesktop} * ${marginFactor}) !important`;
        },
        [isMobileBrowser, gutterWidthDesktop],
    );

    const slideStyles = {
        'swiper-container': {
            display: 'flex',
            gap: gutterWidthDesktop,
        },
        'swiper-slide': {
            flexShrink: 0,
            width: {
                base: getSlideWidth(1.1),
                sm: getSlideWidth(1.4),
                md: getSlideWidth(2),
                lg: getSlideWidth(3),
                xl: getSlideWidth(4),
            },
        },
    };

    const slidesCountBreakpoints = {
        base: 1,
        md: 2,
        lg: 3,
        xl: 4,
    };

    const slidesPerView = useBreakpointValue(
        {
            ...slidesCountBreakpoints,
            base: 1.1,
            sm: 1.4,
        },
        {
            fallback: isMobileBrowser ? 'base' : 'xl',
        },
    );
    const slidesPerGroup = useBreakpointValue(slidesCountBreakpoints);

    if (!destinationSuggestionsData) return;

    const slidesElements = (destinationSuggestionsData?.subsections || []).map(subsection => ({
        name: subsection.label,
        slides: subsection.destinations.map(destination => ({
            id: destination.label,
            body: <DestinationSuggestionItem {...destination} />,
        })),
    }));

    const filters = (destinationSuggestionsData?.subsections || []).map(subsection => ({
        name: subsection.label,
        icon: (
            <Box data-testid="destination-suggestions-category-icon">
                {getFeaturedSubsectionIcon(subsection.icon, {
                    size: 'sm',
                })}
            </Box>
        ),
    }));

    return (
        <Box data-testid={'destination-suggestions-wrapper'} sx={slideStyles}>
            <SectionCarousel
                title={t('title')}
                subtitle={t('subTitle')}
                slideHeight={480}
                filters={filters}
                slideElements={slidesElements}
                sliderParams={{
                    spaceBetween: 24,
                    slidesPerView,
                    slidesPerGroup,
                }}
                sliderOverflow={'hidden'}
                isEdgeToEdgeOnMobile={true}
            />
        </Box>
    );
};
